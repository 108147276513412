<template>
  <div class="Acetylcysteine">
    <div class="game-wrapper"
         :class="[{ stage1: stage ===1  }, { stage2: stage ===2  }, { stage3: stage ===3  }, { stage4: stage ===4  },{ stage5: stage ===5  },{ stage6: stage ===6  }]">

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text big-size green">
            <span>Що ж тут написано?<br></span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-acetylcysteine/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Переглянути
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text desk green">
            <span>Добрий день! У нас є призначення від лікаря!<br></span></p>

          <p class="game-wrapper__stage--text mob green">
            <span>Добрий день!<br>У нас є призначення від лікаря!<br></span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-acetylcysteine/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text big-size small-mob green">
            <span>Ми намагались прочитати, але…<br>схоже на ельфійську</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-acetylcysteine/stage-3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            Взяти призначення
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">


        <div class="game-wrapper__stage--img-circle mr-top">
          <img src="../../assets/img/game-acetylcysteine/stage-4-circle.png" alt="">

          <div class="game-wrapper__stage--white-block bottom stage4">
            <p class="game-wrapper__stage--text  blue"><span>Ніколи такого не було і ось знову!<br>Добре, що я маю досвід у читанні таких текстів</span>
            </p>

          </div>
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>
            До аптеки
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup stage5">
          <img src="../../assets/img/game-acetylcysteine/stage-5-paper.png" alt="">

          <div class="game-wrapper__stage--popup-text">Чергове призначення в стилі вільного польоту руки — виклик, але
            не для Вас! Проте іноді потрібно добре придивитись, аби розпізнати необхідний лікарський
            засіб.<span>Впораєтесь?</span>
          </div>

          <div class="game-wrapper__stage--btn-next-stage small-m hover_type6" @click="nextStage(6)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <AcetylcysteineGame v-if="stage === 6"></AcetylcysteineGame>

    </div>

    <div v-show="stage !== 6" class="notes-wrapper" :class="{ active:  stage === 5  }">
      <span>Інформація про лікарський засіб. РП №UA/18609/01/01. Для професійної діяльності медичних та фармацевтичних
      працівників.</span>


      <span> Ацетилцистеїн-Тева (1 таблетка шипуча містить: ацетилцистеїну 600мг). Характеристики: білі або злегка
      жовтуваті, круглі, пласкі з обох боків, шипучі таблетки, без лінії розлому та із запахом лимона. Лікувальні
      властивості: засоби, що застосовуються при кашлі та застудних захворюваннях. Муколітичний засіб. Найбільш частими
      побічними реакціями є: головний біль, дзвін у вухах, тахікардія, блювання, діарея, стоматит, абдомінальний біль,
      нудота тощо.</span>


      <span> Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. CBG-UA-00895.
      Термін дії матеріалу – 10.2024.</span>

    </div>

    <img :class="{ opacity: stage === 6 }" v-if="stage===1 || stage===2|| stage===3|| stage===4 || stage===6 "
         class="bg"
         src="../../assets/img/game-acetylcysteine/bg.png"
         alt="">

    <img v-else class="bg" src="../../assets/img/game-clopidogrel/bg-type-2.jpg" alt="">
  </div>
</template>

<script>
import AcetylcysteineGame from '../../components/Acetylcysteine-game.vue'

export default {
  name: "Acetylcysteine",
  data: () => ({
    stage: 1,
  }),
  components: {
    AcetylcysteineGame,
  },
  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.Acetylcysteine {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;


  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage6 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;
        height: 110px;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.stage5 {
          height: auto;
        }

        &.bottom {
          top: initial;
          bottom: 0;

        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;


          img {
            max-width: 380px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        &.big-size {
          font-size: 24px;
        }

        span {
          font-family: $EB;
        }

        &.mob {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;

        @media screen and (max-width: 425px) {
          max-width: 274px;        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          margin-top: 40px;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;
        background: white;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        span {
          font-family: $EB;
          display: block;
          margin-top: 10px;
          font-size: 14px;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 40px 30px 40px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;
      }

      &--bottom-text {
        color: #424242;
        font-size: 24px;
        line-height: 120%;

        font-family: $M;

        padding: 48px 30px;
        text-align: center;

        span {
          font-family: $EB;
          margin-top: 10px;
          display: block;
        }

        &.mob {
          display: none;
        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Acetylcysteine {

    padding-top: 0;

    .bg {
      &.opacity {
        opacity: .2;
      }
    }

    .game-wrapper {
      padding: 32px 32px 0 32px;

      &.stage3 {
        padding-top: 32px;
      }

      &__stage {

        &--white-block {

          padding: 10px;
          height: 90px;

          &.bottom {
            height: 130px;

          }

          &.custom-p {
          }

          &.popup {
            padding-bottom: 17px;

            img {
              max-width: 233px;
            }

          }
        }

        &--text {
          font-size: 20px;
          line-height: 20px;

          &.big-size {
            font-size: 24px;
          }

          &.small-mob {
            font-size: 20px !important;
          }

          &.mob {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {

          margin-top: 65px;

          img{
            margin-top: 0;
          }


          &.mr-top {
            margin-top: 0;
            padding-bottom: 18%;

            img {
              margin-top: 0;
            }
          }

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 28px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 20px 20px 20px 20px;

          &.mob {
            display: block;
          }

          &.desk {
            display: none;
          }
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}

</style>