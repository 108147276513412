<template>
  <div class="Acetylcysteine-game">
    <div class="game-stage1" v-show="!result.win && !result.lose">
      <div class="game-bar">
        <div class="game-bar__timer-section">
          <svg
              :class="[{ red: time <= 10 }]"
              viewBox="0 0 28 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle
                cx="14"
                cy="18.3333"
                r="13"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2"/>
            <path
                d="M14 31.3333L14 28.0833"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M14 8.58331L14 5.33331"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M1 18.3333L4.25 18.3333"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M23.75 18.3333L27 18.3333"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M4.80737 9.14093L7.10547 11.439"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M20.8943 25.2277L23.1924 27.5258"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M23.1926 9.14093L20.8945 11.439"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M7.10571 25.2277L4.80762 27.5258"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M14.0003 18.7246L18.6045 10.75"
                stroke="#00A03B"
                stroke-width="2"
            />
          </svg>

          <timer :class="[{ red: time <= 10 }]" :time="prettyTime"></timer>
        </div>
      </div>

      <div class="game">
        <div class="game__image">
          <svg
              width="408"
              height="132"
              viewBox="0 0 408 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.15094 129.453C2.92416 123.543 1.44455 117.386 0.745576 111.12L0.722455 111.075C0.386847 108.159 5.01979 108.189 5.35154 111.075C5.86954 115.404 6.78433 119.676 8.08301 123.837C9.08937 121.748 9.65942 119.376 10.1951 117.147C10.3154 116.646 10.434 116.153 10.5554 115.671C11.6025 111.526 12.4425 107.322 13.1146 103.093C9.64966 99.0953 6.35921 94.9471 3.25366 90.6593H3.24594C1.50617 88.2642 5.52575 85.9869 7.24237 88.3554C9.39593 91.3122 11.6396 94.2005 13.9697 97.0167C15.7673 82.4231 15.8311 67.6223 15.8827 52.9421C15.9116 45.0197 15.8415 37.0874 15.5906 29.1599L13.936 2.39836C13.9291 2.32714 13.9222 2.25593 13.9153 2.18471H13.9228C13.7418 -0.772986 18.2707 -0.684391 18.5521 2.18851C19.4439 11.3599 19.9728 20.5605 20.2517 29.7664L20.2765 30.1657C21.2949 46.5513 22.3107 62.9369 23.3239 79.3225L23.3652 79.9867C23.9831 89.9399 24.602 99.9078 25.6645 109.827C30.3281 114.461 35.2527 118.843 40.4175 122.951C42.7359 124.799 39.457 128.008 37.1424 126.175C33.4794 123.248 29.9352 120.186 26.5171 116.996C26.9685 120.424 27.486 123.845 28.088 127.255C28.5934 130.129 24.134 131.353 23.6248 128.468C22.6537 122.969 21.8857 117.436 21.2588 111.888C19.8277 110.441 18.4213 108.97 17.0402 107.477C16.9768 107.838 16.9124 108.198 16.8471 108.558C16.0794 112.656 15.1536 116.732 13.9963 120.747C12.9779 124.297 11.7512 127.825 9.00851 130.46C7.69308 131.734 5.75272 131.034 5.15094 129.453ZM17.9713 101.686C18.8045 102.625 19.6477 103.556 20.5006 104.477C20.4082 103.477 20.3195 102.476 20.234 101.475C19.9062 97.6181 19.6107 93.7577 19.3372 89.8952C18.9981 93.8379 18.5426 97.77 17.9713 101.686Z"
                fill="#424242"
            />
            <path
                d="M29.7128 68.1074C25.6623 80.1362 32.1546 91.5491 41.3819 99.2325C41.7522 99.5745 42.2223 99.793 42.7257 99.8571C43.229 99.9213 43.74 99.8278 44.1864 99.5898C47.068 98.13 48.5994 95.1646 50.1078 92.4691C51.7682 89.5087 53.1635 86.4112 54.2778 83.2118C56.4638 76.9013 57.2953 70.212 56.7196 63.5681C56.4843 60.9373 53.1437 60.5571 52.1715 62.9636C46.4623 77.1176 48.6226 92.2866 57.6069 104.616C58.2897 105.551 59.6745 106.193 60.7739 105.433C66.5603 101.437 71.8413 96.6663 75.4327 90.6063H71.4363C74.422 95.7805 75.1048 103.437 70.4487 108.011C68.3926 110.033 65.3567 110.946 62.5253 111.204C61.611 111.288 58.0621 111.676 57.5104 110.702C57.1632 110.106 58.2048 109.136 58.5443 108.802C60.7662 106.638 64.0143 105.38 66.8728 104.304C69.7312 103.228 72.5858 102.346 75.5021 101.559C82.3469 99.74 89.3384 98.5074 96.3986 97.8752C99.3381 97.6015 99.3651 93.0356 96.3986 93.3131C89.1481 93.9492 81.9638 95.1776 74.9197 96.9856C68.7475 98.6052 61.8849 100.521 56.7389 104.365C54.7484 105.851 52.8466 107.904 52.8582 110.524C52.8582 113.865 55.7784 115.322 58.7487 115.687C64.8668 116.447 71.9763 114.356 75.479 109.094C79.6915 102.764 79.136 94.6818 75.452 88.3024C75.2443 87.9632 74.9512 87.6826 74.6011 87.4878C74.251 87.293 73.8558 87.1906 73.4538 87.1906C73.0518 87.1906 72.6565 87.293 72.3064 87.4878C71.9564 87.6826 71.6633 87.9632 71.4556 88.3024C68.2731 93.6743 63.59 97.9475 58.4555 101.495L61.6226 102.312C57.5374 96.7005 54.5362 90.3249 53.7377 83.4057C52.9662 76.8173 54.1813 70.3048 56.654 64.1764L52.1098 63.5681C52.6346 69.3573 52.0016 75.1917 50.2466 80.7407C49.3475 83.567 48.1947 86.3088 46.8018 88.9335C45.5944 91.2145 44.3098 94.4156 41.8757 95.6512L44.6801 96.0086C36.668 89.3365 30.6502 79.8625 34.1991 69.3202C35.1404 66.5259 30.6733 65.3283 29.7359 68.1074H29.7128Z"
                fill="#424242"
            />
            <path
                d="M120.365 86.4583L124.265 117.8C124.551 120.104 128.281 121.18 128.809 118.408L134.21 90.0889H129.747L139.53 116.823C140.344 119.055 144.429 118.944 144.074 116.218C143.082 108.528 142.662 100.791 142.369 93.0505C142.099 85.9298 141.192 78.2465 144.394 71.6048L140.76 72.0648C147.025 79.8318 150.535 89.0054 153.147 98.487C154.366 102.92 155.461 107.395 156.704 111.816C158.015 116.412 160.229 121.366 157.244 125.761C155.581 128.209 159.589 130.494 161.24 128.065C164.453 123.339 163.389 118.097 161.865 112.979C160.376 107.972 159.142 102.905 157.772 97.8636C154.91 87.3593 150.96 77.4253 144.031 68.8409C143.144 67.7384 140.992 68.0805 140.398 69.3009C136.984 76.3798 137.408 83.9491 137.697 91.5792C137.998 99.8101 138.399 108.041 139.456 116.218L144.004 115.61L134.241 88.8914C133.539 86.9677 130.237 86.4849 129.778 88.8914L124.377 117.211L128.925 117.815L125.025 86.4735C124.666 83.5993 120.033 83.5613 120.396 86.4735L120.365 86.4583Z"
                fill="#424242"
            />
            <path
                d="M167.761 46.1748C166.866 70.8862 157.677 96.8561 169.269 120.492C170.04 122.096 172.413 121.849 173.265 120.492C184.738 102.187 192.226 81.7315 195.254 60.4238C195.639 57.5724 191.265 56.2912 190.79 59.211C188.557 72.9582 191.948 86.4963 195.481 99.734C196.253 102.57 200.708 101.365 199.944 98.5212C196.635 86.1693 193.167 73.2813 195.254 60.4238L190.79 59.211C187.842 80.1316 180.506 100.218 169.25 118.192H173.246C162.179 95.6243 171.518 69.7723 172.37 46.1786C172.478 43.2436 167.849 43.2474 167.741 46.1786L167.761 46.1748Z"
                fill="#424242"
            />
            <path
                d="M94.5044 97.9471C99.878 98.7075 104.403 93.7272 107.74 90.1573C111.399 86.2585 114.458 81.8523 116.82 77.0792C117.976 74.7429 118.964 72.3291 119.775 69.8559C120.547 67.5178 121.457 64.8718 121.38 62.3816C121.191 56.3444 114.313 55.2077 109.48 56.4205C104.237 57.7397 99.9012 61.5452 96.4679 65.5029C92.7891 69.7253 89.7523 74.4521 87.4528 79.5352C85.2077 84.5535 83.1555 90.1991 83.167 95.7459C83.167 101.528 86.3071 106.068 90.8166 109.505C95.8006 113.306 101.645 116.108 107.574 118.146C113.391 120.146 120.902 122.461 126.977 120.427C129.778 119.484 128.575 115.074 125.747 116.028C121.295 117.53 116.018 115.941 111.671 114.675C107.149 113.357 102.8 111.521 98.713 109.204C94.9789 107.087 90.8089 104.532 88.8415 100.582C86.7198 96.3124 88.1587 91.1914 89.5359 86.9182C92.1938 78.6608 97.0234 70.4566 103.705 64.7311C106.66 62.1991 111.285 59.4315 115.37 60.8381C117.523 61.5756 116.67 63.7807 116.234 65.4839C115.692 67.6211 115.006 69.7204 114.182 71.7682C112.375 76.2517 109.905 80.4473 106.853 84.219C105.331 86.0966 103.676 87.8651 101.899 89.511C100.565 90.7504 97.7988 93.8564 95.7273 93.5675C94.4813 93.3888 93.2584 93.8716 92.8804 95.1604C92.5718 96.2287 93.2662 97.7913 94.4967 97.9661L94.5044 97.9471Z"
                fill="#424242"
            />
            <path
                d="M229.215 58.2987C227.913 64.2 227.588 70.27 228.254 76.2734C228.914 82.0065 230.21 88.2642 233.111 93.3434C235.491 97.5253 240.459 100.848 244.946 97.32C247.102 95.6282 248.468 92.9442 249.818 90.6289C251.317 88.0538 252.701 85.4179 253.969 82.7212C256.927 76.4211 259.24 69.8457 260.874 63.0927L256.326 62.4882L255.994 78.9689L255.832 86.8347C255.782 89.3173 255.446 91.9975 255.832 94.4383C255.98 95.4751 256.438 96.445 257.147 97.225C257.722 97.8294 258.825 98.0956 259.315 98.7038C260.372 100.023 258.841 103.517 258.467 104.905C257.114 109.873 255.418 114.744 253.39 119.484H257.857C257.086 114.569 256.873 109.014 259.381 104.513C261.445 100.81 265.252 98.1374 269.642 98.4567C272.612 98.6772 272.597 94.1113 269.642 93.8946C263.856 93.4726 258.567 96.974 255.666 101.73C252.194 107.433 252.387 114.371 253.39 120.697C253.776 123.218 257.097 122.476 257.857 120.697C259.883 115.956 261.579 111.085 262.934 106.117C263.516 103.965 264.307 101.631 264.203 99.373C264.087 96.9056 262.841 95.7651 260.989 94.3774C260.087 93.7007 260.334 92.7579 260.353 91.6896C260.376 90.3172 260.407 88.9409 260.434 87.5685L260.604 79.3262L260.943 62.473C260.997 59.9258 257.008 59.3404 256.399 61.8647C254.909 68.1274 252.802 74.2316 250.107 80.0904C248.757 82.9975 247.267 85.8374 245.636 88.6102C244.676 90.2411 242.759 94.7956 240.236 94.1912C237.898 93.6323 236.583 90.2107 235.853 88.3098C234.718 85.3145 233.86 82.2236 233.292 79.0753C232.141 72.5968 232.272 65.96 233.678 59.5305C234.295 56.6677 229.82 55.4436 229.215 58.3177V58.2987Z"
                fill="#424242"
            />
            <path
                d="M195.281 98.5748C195.298 100.625 195.742 102.649 196.586 104.523C197.43 106.397 198.656 108.079 200.187 109.467C201.229 110.41 203.111 110.368 203.821 109.007C212.485 92.4159 209.318 73.3729 212.875 55.6301H208.408C215.27 76.5381 223.319 97.0494 232.518 117.067C233.744 119.728 237.733 117.42 236.514 114.763C227.491 95.1034 219.599 74.9589 212.875 54.4249C212.219 52.429 208.879 52.083 208.408 54.4249C204.963 71.6241 208.2 90.6747 199.825 106.711L203.459 106.251C202.356 105.288 201.47 104.109 200.859 102.788C200.247 101.467 199.924 100.034 199.91 98.5824C199.863 95.6512 195.234 95.6436 195.281 98.5824V98.5748Z"
                fill="#424242"
            />
            <path
                d="M267.409 97.2287C269.114 103.134 271.011 108.981 273.099 114.77C274.137 117.64 275.217 120.499 276.363 123.335C277.242 125.514 278.191 128.133 280.031 129.73C282.114 131.536 284.244 130.262 285.297 128.167C286.751 125.274 288.117 122.328 289.54 119.423C292.253 113.807 294.964 108.193 297.672 102.582L293.428 102.038L297.699 114.394C298.655 117.158 303.126 115.968 302.162 113.181L297.892 100.825C297.305 99.1333 294.578 98.3806 293.648 100.281L287.144 113.751L284.058 120.153L282.43 123.522C282.045 124.328 281.501 126.16 280.787 126.719L282.423 126.054C283.017 126.343 283.144 126.312 282.809 125.955C282.619 125.713 282.447 125.459 282.292 125.194C281.976 124.671 281.69 124.13 281.435 123.575C280.911 122.457 280.482 121.294 280.031 120.153C279.002 117.52 278.014 114.87 277.065 112.204C275.167 106.881 273.436 101.497 271.872 96.0501C271.058 93.2292 266.591 94.4268 267.409 97.2629V97.2287Z"
                fill="#424242"
            />
            <path
                d="M308.646 68.2708C309.531 83.3106 311.119 98.2857 313.41 113.196C313.796 115.785 317.958 115.097 317.955 112.592V91.4007C317.955 88.1312 317.858 84.8465 317.955 81.577C318.028 79.4404 318.236 76.6727 320.269 75.4295H317.931C318.892 76.1214 319.347 77.3646 319.791 78.4063C320.524 80.1285 321.203 81.8735 321.909 83.6071L326.005 93.6666C326.869 95.7842 329.628 95.8564 330.469 93.6666C332.957 87.2121 335.938 80.9529 339.387 74.9429L335.144 74.3992C341.251 89.4596 342.596 105.988 339.001 121.815C338.346 124.674 342.805 125.894 343.465 123.028C347.296 106.41 345.952 89.0393 339.607 73.1865C338.917 71.4681 336.436 70.7914 335.364 72.6428C331.732 79.0032 328.594 85.6258 325.978 92.4538H330.48L325.542 80.3262C324.223 77.0909 323.247 73.6503 320.285 71.4985C319.932 71.2893 319.528 71.1788 319.116 71.1788C318.704 71.1788 318.3 71.2893 317.947 71.4985C312.261 74.9847 313.318 82.7973 313.318 88.4391V112.603L317.866 111.995C315.649 97.5026 314.116 82.9291 313.268 68.2746C313.098 65.3548 308.465 65.3358 308.639 68.2746L308.646 68.2708Z"
                fill="#424242"
            />
            <path
                d="M301.429 64.0396C298.964 62.9219 295.808 61.1426 293.035 61.1046C290.126 61.059 289.096 63.5568 288.684 65.9709C287.684 71.8142 289.497 77.5625 291.5 83.0028C295.068 92.7011 299.724 102.392 307.852 109.186C309.703 110.737 313.137 108.668 311.486 106.422C308.117 101.907 306.43 96.3862 306.706 90.7888C306.841 87.8538 302.208 87.8614 302.077 90.7888C301.769 97.1973 303.673 103.52 307.478 108.726L311.111 105.962C304.654 100.556 300.492 93.1953 297.39 85.5272C295.735 81.4365 294.065 77.2013 293.301 72.8445C293.092 71.6798 292.989 70.4991 292.992 69.3164C292.99 68.7438 293.018 68.1715 293.077 67.6018C293.131 67.1342 293.544 66.2256 293.424 65.8302C293.644 65.3854 293.486 65.3284 292.946 65.6667L293.471 65.815C293.884 66.1344 294.724 66.2294 295.211 66.4043C296.526 66.8719 297.811 67.4041 299.068 67.982C301.768 69.2024 304.11 65.2714 301.406 64.0396H301.429Z"
                fill="#424242"
            />
            <path
                d="M359.964 103.164C359.787 95.4916 362.209 88.1275 363.212 80.581C363.706 76.8667 363.837 73.1372 363.189 69.4343C362.695 66.64 360.57 63.2184 357.163 64.5148C354.031 65.7047 352.677 70.2935 351.879 73.1714C350.866 76.9993 350.106 80.8877 349.603 84.8124C348.534 92.5224 348.445 100.51 350.729 108.026C352.947 115.287 357.225 121.788 363.586 126.141C366.04 127.818 368.358 123.86 365.924 122.203C355.964 115.386 352.99 102.791 353.58 91.4921C353.908 85.1431 354.837 78.2429 357.052 72.2324C357.309 71.5107 357.624 70.8103 357.993 70.1376C358.097 69.9475 358.467 69.5141 358.483 69.3088C358.537 68.6815 358.24 69.2023 358.155 68.7081C358.24 69.2137 358.587 69.7764 358.691 70.3201C358.849 71.1999 358.951 72.0888 358.996 72.9813C359.126 76.0906 358.904 79.2046 358.332 82.2652C357.148 89.2034 355.2 96.0542 355.362 103.145C355.431 106.076 360.06 106.087 359.991 103.145L359.964 103.164Z"
                fill="#424242"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M371.91 61.1575C369.395 62.512 366.937 61.6734 364.804 60.2608C364.184 62.1668 360.945 62.634 360.372 60.2337C359.67 57.3063 359.18 51.6265 362.756 50.2198C364.419 49.5659 366.201 50.1932 367.771 50.7939C369.453 51.4402 371.212 52.1435 372.62 53.284C375.382 55.5081 375.085 59.4505 371.91 61.1575ZM364.685 54.6038C364.644 54.6899 364.608 54.7815 364.578 54.8776C365.066 54.8966 365.566 55.0747 366.023 55.4662C366.119 55.5478 366.218 55.6346 366.321 55.7243L366.321 55.7243C367.171 56.4668 368.251 57.4107 369.387 57.2683C370.615 57.1096 369.419 56.5341 368.743 56.2091C368.642 56.1604 368.552 56.1173 368.485 56.0821C367.749 55.7003 366.99 55.3626 366.212 55.0709C365.78 54.915 365.348 54.7667 364.909 54.6337C364.822 54.6242 364.748 54.6142 364.685 54.6038ZM367.898 66.959C367.957 81.4614 368.269 95.9626 368.835 110.463C368.951 113.386 373.58 113.401 373.464 110.463C372.899 95.9652 372.586 81.464 372.527 66.959C372.527 64.024 367.898 64.0164 367.898 66.959ZM379.259 66.4765V66.4724C379.247 66.4786 379.236 66.4848 379.224 66.4909L379.259 66.4765ZM378.834 66.6539C378.793 66.6667 378.753 66.6784 378.712 66.6891H378.75L378.834 66.6539ZM379.395 62.5167C379.245 62.26 379.053 62.0267 378.827 61.8266C378.381 61.4173 377.8 61.1798 377.191 61.1575C376.579 61.1629 375.992 61.4028 375.556 61.8266C375.121 62.254 374.877 62.8338 374.877 63.4385C374.872 63.7393 374.93 64.0378 375.046 64.3157C375.11 64.4677 375.191 64.6118 375.287 64.7454C375.298 64.8038 375.308 64.8624 375.316 64.9212C375.392 65.281 375.532 65.6246 375.729 65.9363C376.086 66.5374 376.666 66.9791 377.346 67.169C378.026 67.3589 378.754 67.2823 379.378 66.9552C379.873 66.6517 380.253 66.1976 380.462 65.6618C380.671 65.1259 380.697 64.5376 380.536 63.986C380.366 63.3675 379.956 62.8399 379.395 62.5167Z"
                fill="#424242"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M379.38 95.5266C379.314 95.7078 379.252 95.8863 379.191 96.0617C378.844 97.0681 379.206 97.8672 379.854 98.347C380.749 103.856 381.457 109.394 381.979 114.952C382.252 117.853 386.882 117.88 386.608 114.952C386.02 108.604 385.195 102.281 384.136 95.9963C384.954 94.0147 386.091 92.0639 387.624 90.5909C389.728 88.5727 391.289 91.924 392.082 93.6262C392.11 93.6875 392.138 93.7467 392.164 93.8034C392.882 95.3241 395.416 95.2481 396.161 93.8034C396.294 93.5481 396.423 93.2911 396.547 93.0323L402.792 128.962C403.293 131.851 407.757 130.624 407.255 127.749L398.95 79.9612C398.826 78.5092 398.576 77.0618 398.198 75.6348C397.426 72.7454 393.241 74.0076 393.734 76.8475L394.385 80.5886C394.567 82.9834 394.323 85.3912 393.667 87.7024C391.856 85.752 389.442 84.5606 386.582 85.7969C385.127 86.4236 383.885 87.5453 382.836 88.8946C382.15 85.425 381.392 81.9686 380.563 78.5276C379.876 75.6725 375.409 76.8814 376.1 79.7404C377.364 84.9682 378.458 90.233 379.38 95.5266Z"
                fill="#424242"
            />
          </svg>
        </div>

        <div class="game__input">
          <div
              v-for="(item, key) in inputArr"
              :key="key"
              class="game__input--item"
          >
            {{ item }}
          </div>
        </div>

        <div class="game__tip">
          <span v-if="!error" class="grey">Оберіть потрібні літери</span>
          <span v-if="error" class="red">не правильна літера</span>
        </div>

        <div class="game__btns">
          <div class="game__btns--list">
            <button
                class="game__btns--item"
                v-for="(item, i) in letters"
                :key="i"
                :class="{
                active: item.active,
                invalid: item.active && item.key === null,
                redActive: item.key === null,
              }"
                @click="item.active ? null : latterClick(item, i)"
            >
              {{ item.value }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block stage1">
        <p class="win__text desk white">
          <span>Ацетилцистеїн!<br>Це те, що говорив лікар</span>
        </p>

        <div class="win__arrow-down"></div>
      </div>

      <img
          class="win__img-circle"
          src="../assets/img/game-acetylcysteine/win.png"
          alt=""
      />

      <div class="win__text-center">
        Якщо в древніх єгипетських ієрогліфах ще є нерозгадане, то це тільки тому, що в команді не було Вас!<br>
       <span>Ацетилцистеїн-Тева – лікування вологого кашлю.<br>Якість від Тева.</span>
      </div>

    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span>Може в іншій аптеці зрозуміють?<br>Чи Ви подивитеся ще раз?</span>
        </p>


        <div class="lose__arrow-down"></div>
      </div>

      <img
          class="lose__img-circle"
          src="../assets/img/game-acetylcysteine/lose.png"
          alt=""
      />

      <div class="lose__red-text">Не вистачило часу…</div>

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose || result.win || !result.win && !result.lose" class="notes-wrapper">
      <span>Інформація про лікарський засіб. РП №UA/18609/01/01. Для професійної діяльності медичних та фармацевтичних
      працівників.</span>


      <span>Ацетилцистеїн-Тева (1 таблетка шипуча містить: ацетилцистеїну 600 мг). Характеристики: білі або злегка
      жовтуваті, круглі, пласкі з обох боків, шипучі таблетки, без лінії розлому та із запахом лимона. Лікувальні
      властивості: засоби, що застосовуються при кашлі та застудних захворюваннях. Муколітичний засіб. Найбільш частими
      побічними реакціями є: головний біль, дзвін у вухах, тахікардія, блювання, діарея, стоматит, абдомінальний біль,
      нудота тощо.</span>


      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. CBG-UA-00895.
      Термін дії матеріалу – 10.2024.</span>
    </div>


  </div>
</template>

<script>
import Timer from "./Timer.vue";

export default {
  name: "Acetylcysteine-game",
  data: () => ({
    minutes: 0,
    secondes: 0,
    time: 60,
    timer: null,

    error: false,

    result: {
      win: false,
      lose: false,
    },

    inputArr: new Array(13).fill(""),

    letters: [
      {value: "П", active: false, key: null},
      {value: "Л", active: false, key: 6},
      {value: "О", active: false, key: null},
      {value: "К", active: false, key: null},
      {value: "Т", active: false, key: 4},
      {value: "Ж", active: false, key: null},
      {value: "С", active: false, key: 9},
      {value: "Е", active: false, key: 3},
      {value: "Ї", active: false, key: 12},
      {value: "Ж", active: false, key: null},
      {value: "Ц", active: false, key: 2},
      {value: "Й", active: false, key: null},
      {value: "Ц", active: false, key: 7},
      {value: "И", active: false, key: 8},
      {value: "И", active: false, key: 5},
      {value: "Н", active: false, key: 13},
      {value: "А", active: false, key: 1},
      {value: "Т", active: false, key: 10},
      {value: "У", active: false, key: null},
      {value: "Е", active: false, key: 11},
    ],
    tip: {
      text: "не правильна  літера",
      color: "red",
    },
  }),

  watch: {
    time: function (val) {
      if (val === 0) {
        const valid = this.validate();
        this.result[valid ? "win" : "lose"] = true;
        window.scrollTo(0, 0)
      }
    },
  },

  components: {
    timer: Timer,
  },

  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },

  mounted() {
    this.start();
    // start timer
  },

  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            clearInterval(this.timer)
            // this.reset()
          }
        }, 1000)
      }
    },

    reset() {
      this.time = 60;
      this.timer = null;
      this.result.lose = false;
      this.result.win = false;
      this.error = false;

      this.inputArr = new Array(13).fill("");

      this.letters.forEach((el, i) => {
        el.active = false;
      });

      window.scrollTo(0, 0)

      this.start();
    },

    validate() {
      return this.inputArr.join("") === "АЦЕТИЛЦИСТЕЇН";
    },

    latterClick(item, i) {
      this.error = !item.key;
      this.letters[i].active = true;

      if (item.key) {
        this.inputArr[item.key - 1] = item.value;
      }

      if (this.validate()) {
        setTimeout(() => {
          this.result.win = true;
          window.scrollTo(0, 0)
        }, 500)
        // clearInterval(this.timer);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Acetylcysteine-game {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .timer {
    font-family: $B;
  }

  .game-bar {
    display: flex;
    justify-content: space-between;

    max-width: 650px;
    padding: 0 20px;
    margin: 0 auto;

    &__timer-section {
      margin: auto;
      display: flex;
      align-items: center;

      svg {
        max-width: 48px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }
  }

  .game {
    margin: 10px auto;
    max-width: 610px;
    padding: 0 20px;

    &__image {
      padding: 10px;
      width: 100%;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;

      svg {
        width: 100%;
      }
    }

    &__input {
      margin-top: 30px;

      background: #ffffff;
      border: 1px solid #00a03b;
      border-radius: 20px;

      display: flex;

      &--item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 52px;
        opacity: 0.5;
        border-left: 1px solid #424242;

        font-family: $R;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

        color: #00a03b;
        position: relative;

        &:first-child {
          border-left: none;
        }
      }
    }

    &__tip {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 30px 0;

      font-weight: 700;
      font-size: 18px;
      line-height: 120%;

      font-family: $M;
      line-height: 120%;

      .grey {
        color: $grey;
        opacity: 0.4;
      }

      .red {
        color: #C40000;
      }
    }

    &__btns {
      margin: 0 auto ;
      max-width: 456px;

      &--list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: -20px;
        margin-right: -20px;
      }

      &--item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        background: #00a03b;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        border: none;
        // outline: none;

        font-family: $R;
        font-weight: 800;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;

        width: 48px;
        height: 48px;

        margin-right: 20px;
        position: relative;
        margin-bottom: 20px;

        &:before {
          content: '';
          position: absolute;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);


          width: 64px;
          height: 64px;

          background: rgba(196, 0, 0, 0.2);
          border-radius: 50%;
          opacity: 0;
        }

        &:after {
          content: '';

          position: absolute;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);


          width: 80px;
          height: 80px;


          background: rgba(196, 0, 0, 0.1);
          border-radius: 50%;
          opacity: 0;
        }

        &.redActive {
          position: relative;

          &:active {
            background: #C40000;

            &:before {
              opacity: 1;
            }

            &:after {
              opacity: 1;
            }
          }
        }

        &.active {
          opacity: 0.2;
          box-shadow: none;

          // &:focus {
          //   opacity: 1;
          //   background: $green;
          //   box-shadow: 0 0 0 16px rgba(0, 160, 59, 0.1),
          //     0 0 0 8px rgba(0, 160, 59, 0.2), 0 0 20px rgba(0, 0, 0, 0.4);
          // }

          &.invalid {
            background: $red;
            box-shadow: none;

            // &:focus {
            //   opacity: 1;
            //   box-shadow: 0 0 0 16px rgba(196, 0, 0, 0.1),
            //     0 0 0 8px rgba(196, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.4);
            // }
          }
        }
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 24px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 308px;
      width: 100%;
      height: auto;
      display: block;
      margin: 50px auto 0 auto;
      border-radius: 50%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);


    }

    &__red-text {
      color: #C40000;
      text-align: center;
      font-size: 32px;
      line-height: 120%;
      font-family: $B;
      margin-top: 29px;
    }

    &__repeat {
      margin: 29px auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      background: white;
      padding: 14px 15px;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    position: relative;
    padding: 0 20px 0 20px;

    max-width: 650px;
    width: 100%;
    margin: 0 auto;

    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
    }

    &__text {
      font-size: 24px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $green;
    }

    &__img-circle {
      max-width: 308px;
      width: 100%;
      height: auto;
      display: block;
      margin: 40px auto 0 auto;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      color: $green;
      font-size: 24px;
      line-height: 120%;
      font-family: $R;
      span{
        font-family: $B;
        margin-top: 5px;
        display: inline-block;
      }
    }

    &__info-text-btn {
      margin-top: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 0;
      position: absolute;
      width: calc(100% - 40px);
      background: white;
      padding: 20px 20px 40px 20px;

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
    .game-bar {
      padding-top: 20px;

      &__timer-section {

        svg {
          max-width: 41px;

        }
      }
    }

    .game {
      padding-bottom: 20px;

      &__tip {
        font-size: 14px;

        margin: 20px auto 20px auto;
      }

      &__image {
        svg {
          width: auto;
          height: 64px;
        }
      }

      &__input {
        margin-top: 16px;
      }

      &__btns {
        margin-top: 16px;

        &--item {
          margin-right: 15px;
          margin-bottom: 15px;

          &.active {
            opacity: 0.2;

            box-shadow: none;
            // &:focus {
            //   opacity: 1;
            //   background: $green;
            //   box-shadow: 0 0 0 16px rgba(0, 160, 59, 0.1),
            //     0 0 0 8px rgba(0, 160, 59, 0.2), 0 0 20px rgba(0, 0, 0, 0.4);
            // }

            &.invalid {
              background: $red;
              box-shadow: none;

              // &:focus {
              //   opacity: 1;
              //   box-shadow: 0 0 0 16px rgba(196, 0, 0, 0.1),
              //     0 0 0 8px rgba(196, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.4);
              // }
            }
          }
        }
      }
    }

    .lose {
      padding-top: 22px;
      padding-bottom: 22px;

      &__repeat {
        margin-top: 27px;
      }

      &__red-text {
        font-size: 24px;
        line-height: 120%;
        margin-top: 38px;
      }

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;


        top: 22px;
      }

      &__text {
        font-size: 18px;
        line-height: 120%;
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 20px;
      }
    }

    .win {
      padding-top: 22px;
      padding-bottom: 22px;

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;


        top: 22px;
      }

      &__text {
        font-size: 20px;
        line-height: 120%;
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 20px;
      }

      &__text-center {
        line-height: 120%;
        margin-top: 20px;
        font-size: 20px;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;
        }
      }
    }

    .notes-wrapper {

      display: none;
    }
  }

}

</style>
